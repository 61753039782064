export const futureProductData = [
    {
        title: 'Magi',
        underTitle: 'Oppdagende skrive- og leseopplæring',
        img: new URL(`../img/img1.jpeg`, import.meta.url),
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a lacus augue. Suspendisse vitae nulla ut felis scelerisque rutrum. Praesent egestas ex nulla. Maecenas tellus quam, aliquam nec commodo quis, pulvinar ut elit. Suspendisse potenti. Cras gravida ligula leo, eget porta dolor tristique ac. Duis ut lacinia dui, vel pulvinar odio.',
        url: `#`,
    },
    {
        title: 'Magi',
        underTitle: 'Oppdagende skrive- og leseopplæring',
        img: new URL(`../img/img2.jpeg`, import.meta.url),
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a lacus augue. Suspendisse vitae nulla ut felis scelerisque rutrum. Praesent egestas ex nulla. Maecenas tellus quam, aliquam nec commodo quis, pulvinar ut elit. Suspendisse potenti. Cras gravida ligula leo, eget porta dolor tristique ac. Duis ut lacinia dui, vel pulvinar odio.',
        url: `#`,
    },
    {
        title: 'Magi',
        underTitle: 'Oppdagende skrive- og leseopplæring',
        img: new URL(`../img/img3.jpeg`, import.meta.url),
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a lacus augue. Suspendisse vitae nulla ut felis scelerisque rutrum. Praesent egestas ex nulla. Maecenas tellus quam, aliquam nec commodo quis, pulvinar ut elit. Suspendisse potenti. Cras gravida ligula leo, eget porta dolor tristique ac. Duis ut lacinia dui, vel pulvinar odio.',
        url: `#`,
    },
    {
        title: 'Magi',
        underTitle: 'Oppdagende skrive- og leseopplæring',
        img: new URL(`../img/img4.jpeg`, import.meta.url),
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a lacus augue. Suspendisse vitae nulla ut felis scelerisque rutrum. Praesent egestas ex nulla. Maecenas tellus quam, aliquam nec commodo quis, pulvinar ut elit. Suspendisse potenti. Cras gravida ligula leo, eget porta dolor tristique ac. Duis ut lacinia dui, vel pulvinar odio.',
        url: `#`,
    }
];
